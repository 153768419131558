@import url('https://fonts.googleapis.com/css?family=Assistant');
@import "~@ng-select/ng-select/themes/default.theme.css";

:not(.fa) {
  font-family: Assistant;
}

body {
  direction: rtl;
  color: var(--graydark);
}

* {
  text-align: right;
}

.wrap {
  padding: 20px;
  display: table;
}

.progress-bar {
  border-radius: 57px;
  background-color: var(--pink);
}

.btn-round {
  border-radius: 57px;
}

.form-login, app-add-man-steps .form-asks {
  background: #fff;
  border: 1px solid var(--pink);
  width: 345px;
  display: table;
  margin: 20px auto;
  margin-top: 20px;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.header {
  background-color: var(--pink);
  color: #fff;
  font-size: 15pt;
  text-align: center;
  padding: 8px;
}

/* [class^="btn-"]{
    
} */

.btn {
  padding: .225rem .75rem;
}

.row-table {
  float: none;
  display: table;
  clear: both;
  margin: 15px auto;
}

.row-flex, .row-flex-separated, .row-flex-between {
  float: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  clear: both;
  margin: 15px auto;
}

.row-flex-between {
  justify-content: space-between;
}

.row-flex-separated {
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.expand-compress i {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  background: #f2f2f2;
  margin-left: 5px;
}

.expand-compress i, .expand-compress {
  outline: none;
}

/* .form-control:focus {
    border-color: var(--pink);
    box-shadow: 0 0 0 0.2rem rgba(233, 115, 115,.25);
} */

.padding-20 {
  padding: 20px;
}

.note {
  color: var(--pink) !important;
  font-size: 82%;
  margin-top: -10px;
}

.pink-color {
  color: var(--pink) !important;
}
.blue-color {
  color: var(--blue) !important;
}
.gray-color {
  color: var(--gray) !important;
}

i.left-space {
  padding-left: 5px;
}

i.right-space {
  padding-right: 5px;
}

input[type="checkbox"].left-space {
  margin-left: 5px;
}

input[type="checkbox"].right-space {
  margin-left: 5px;
}

.bordered {
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.invalid .right-label {
  color: brown;
}

/* .invalid input.form-control{
  border-color: brown;
} */

.invalid {
  background: #a52a2a1f;
  padding: 5px;
  margin-right: -5px;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 5px;
}

app-login, app-add-user, app-password, app-reset-password {
  margin: auto;
  margin-top: -370px;
  display: table;
}

app-add-man-steps .btn, app-add-man-steps .form-control {
  border-radius: 57px;
  min-width: 72px;
}

app-add-man-steps .bordered {
  padding: 0px;
  border: none;
}

app-add-man-steps app-family-status-asks .form-control {
  width: 130px !important;
}

app-add-man-steps app-add-img *{
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
app-logo-menu-mobile .fa.fa-bars{
  color: #fff !important;
  font-size: 17pt;
  top: 1px;
  position: relative;
}

 .alert-top{ 
  width: 95%;
  margin: 20px auto;
  display: table;
  margin-top: 20px;
}
.max-800{
  max-width: 800px;
}
app-add-man .alert-top{ 
  width: 95%;
  max-width: 800px;
  margin: auto;
  display: table;
  margin-top: 20px;
}
app-setting .alert-top{
  margin: 0 0 20px 0;
}

app-add-man ul a.nav-link { /* .edit-form */
  display: flex;
  text-align: center;
  padding: 0px;
}

app-add-man .edit-form a.nav-link span{
  /* padding-top: 6px; */
  margin-left: 20px;
}

@media screen and (max-width: 430px) {
  app-add-man .edit-form a.nav-link span {
    margin-left: 0px; 
  }
  app-add-man .div-switch {
    text-align: center;
    font-size: 15.5px;
  }
  app-add-man i.fa.circled {
    margin: auto; 
    margin-left: 10px;
  }
  .page-title {
    top: 110px !important;
    width: 100% !important;
    left: 0 !important;
  }
}

app-add-man .edit-form i.fa.fa-chevron-left{
  display: none;
}
app-add-man .div-switch {
  padding: .5rem 1rem;
  width: 100%;
  width: -webkit-fill-available;
}

app-logo-menu {
  padding: 8px 15px;
  display: table;
  width: 100%;
}
.title-check-info .fa-chevron-right, .title-check-info .fa-chevron-left{
  font-size: 12px;
  -webkit-text-stroke: 0.7px #ececec;
}
/* ------app-filter and app-free-search------------------------------------------------------ */
 .ng-select .ng-select-container .ng-value-container .ng-placeholder,
.ng-select .ng-select-container .ng-value-container .ng-input>input{
  padding-right: 10px !important;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input{
  padding-right: 10px !important;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #b8dbff99;
  border-right: 1px solid #b8dbff99;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #ddeaf7;
}
.ng-select .ng-select-container {
  border-radius: .25rem;
}
.ng-select .ng-value-container {
  min-width: 123px;
}
.ng-select .ng-input {
  width: 0px;
}
/* -------------------------------------------------------------------------- */
.ml-15 {
  margin-left: 15px;
}

app-offer .circled i{
  cursor: pointer;
  border-radius: 90px;
  padding: 0.35em 0.5em;
  font-size: 1.3em;
  margin-right: 4px;
  background: #fff;
  box-shadow: 0px 0px 2px 0.6px  var(--gray);
}
app-offer .circled i:hover {
  box-shadow: 0px 0px 2px 1.8px  var(--gray);
}
app-users-manage .circled i{
  cursor: pointer;
  border-radius: 90px;
  padding: 0.35em 0.5em;
  font-size: 1.3em;
  margin-right: 4px;
  background: #fff;
  box-shadow: 0px 0px 2px 0.6px  var(--gray);
}
app-users-manage .circled i:hover {
  box-shadow: 0px 0px 2px 1.8px  var(--gray);
}

app-admin-folder i.fa.circled {
  cursor: pointer;
  display: inline-block;
  border-radius: 60px;
  padding: 0.31em 0.4em;
  font-size: 1.1em;
  margin-right: 4px;
  -webkit-text-stroke: 1px #fff;
}
app-admin-folder i.fa.circled:hover {
  background-color: rgb(0 0 0 / 5%);
  -webkit-text-stroke: 1px rgb(0 0 0 / 5%);
}
app-admin-folder i.fa.circled.del {
  box-shadow: 0px 0px 2px 0.5px brown;
  color: #a52a2aab;
}
app-admin-folder i.fa.circled.info {
  box-shadow: 0px 0px 2px 0.5px var(--blue);
  color: var(--blue);
}

app-add-man i.fa.circled {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px 0.5px #fff;
  padding: 0.34em 0.4em;
  font-size: 1.4em;
  margin-right: 4px;
}


.finish-register * {
  text-align: center;
}

/* .finish-register-shadchan * {
  text-align: right;
} */

.male-img {
  background-image: url("assets/img/man-profile.png");
}

.female-img {
  background-image: url("assets/img/woman-profile.png");
}

.shadchan-img{
  background: #b7b7b7;
}

button.close:focus {
  outline: none !important;
  color: red !important;
}
.page-title{
  color: #000;
  text-align: center;
  position: absolute;
  top: -118px;
  /* width: 100%; */
  left: 50%;
}
.page-title.outside{
  top: 110px;
  width: 100%;
  left: 0;
}
/* all */
@media screen and (min-width: 430px) {
  app-offer, app-offer-pair, app-user-details, app-system-alerts, app-alerts, app-alerts-user, app-edit-notes-status,
  app-setting, app-groups, app-add-man, app-add-shadchan, app-del-shadchan, app-users-manage, app-db-manage{
    display: table;
    margin-top: 20px;
    float: right;
    width: -webkit-fill-available;
    margin-right: 214px;
    position: absolute;
  }
  app-user-details{
    margin-right: 170px;
  }
}

app-del-shadchan * {
  text-align: center;
  margin: auto;
}

app-add-man .register-form ul.nav {
  background: var(--pink);
}

app-add-man .register-form .nav .fa-chevron-left {
  margin-right: 19px;
}

.register-form .small-input, .form-asks .small-input {
  width: 40px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 10px;
  height: 24px;
}

.div-noResults{
  width: 100%;
  max-width: 1009px; 
  margin: auto;
  margin-top: 30px;
}

.sidebar li .fa, .sidebar-mobile li .fa {
  float: left;
  padding-top: 4px;
}
.offer-box .reaction .fa-times{
  color: var(--blue);
}
.offer-box .reaction .fa-thumbs-up{
  /* color: var(--pink); */
  color: #fff;
  -webkit-text-stroke: 3px var(--pink);
}
.offer-box .reaction .fa-question{
  color: var(--gray);
}
.bs-datepicker *{
  text-align: center;
}
/* ----------------------------- */

.sidebar li.nav-item .fa {
  float: left;
  padding-top: 4px;
}

.sidebar li.nav-item {
  border-bottom: 1px solid rgba(229, 229, 229, 0.2);
}

.sidebar .fa.fa-bars {
  margin: 15px;
  color: #fff;
  font-size: 24px;
}

.sidebar ul, .sidebar .above, .sidebar .under {
  width: 194px;
  float: right;
  background: var(--blue);
}
.sidebar .above{
  float: none !important;
  padding-top: 30px;
}

.sidebar tabset {
  display: flex;
}

.sidebar .tab-content {
  float: left;
  margin-top: -150px;
  width: 80%;
}

/* ------bootstrap overrides----------------------------------------- */
.bg-light {
  background-color: #f3f3f3!important;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  /* background-color: #0000000a; */
  background-color:  var(--graylight);
}

.nav-pills .nav-link {
  color: #fff;
  text-align: center;
}

.nav-pills .nav-link:hover {
  /* background-color: #0000000a; */
  background-color:  var(--graylight);
}

/* ------------------------------------------- */

ul {
  padding-inline-start: 0px;
}
/* my colors */
:root {
  --pink: #E97373;
  --pinkdark: #d46767;
  --pinklight: #FFEEEE;
  --gray: #969696; 
  --graylight: #C4C4C4;
  --graydark: #7F7F7F;
  --light: #f8f9fa;
  --dark: #343a40;
  --blue: #2699A9;
  --bluedark: #207d8a;
  --bluelight: #A8D6DD;
}

.btn-gray {
  color: #fff;
  background-color:var(--gray);
  border-color: var(--gray);
}

.btn-gray:hover {
  color: #fff;
  background-color: var(--graydark);
  border-color: var(--graydark);
}

.btn-gray:focus, .btn-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-gray.disabled, .btn-gray:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active, .show>.btn-gray.dropdown-toggle {
  color: #212529;
  background-color: var(--graydark);
  border-color: var(--graydark);
}

.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus, .show>.btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light {
  color: var(--graydark);
  border-color: var(--gray);
  /* background-image: linear-gradient(#f8f9fab3, #f1f2f3,#f1f2f3); */
}

.btn-light:hover {
  color: var(--graydark);
  border-color: var(--gray);
}

.btn-light.disabled, .btn-light:disabled {
  color: var(--graydark);
  border-color: var(--gray);
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show>.btn-light.dropdown-toggle {
  color: var(--graydark);
  border-color: var(--gray);
}

.btn-blue {
  color: #fff;
  background-color: var(--blue);
  border-color: var(--blue);
  /* background-image: linear-gradient(#f8f9fab3, #f1f2f3,#f1f2f3); */
}

.btn-blue:hover {
  background-color: var(--bluedark);
  border-color: var(--bluedark);
}

/* .btn-blue.disabled, .btn-blue:disabled {
    color: #fff; background-color: var(--blue);
    border-color: var(--blue);
  } */

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show>.btn-blue.dropdown-toggle {
  color: #fff;
  background-color: var(--bluedark);
  border-color: var(--bluedark);
}
.btn-pink {
  color: #fff;
  background-color: var(--pink);
  border-color: var(--pink);
  /* background-image: linear-gradient(#f8f9fab3, #f1f2f3,#f1f2f3); */
}

.btn-pink:hover {
  background-color: var(--pinkdark);
  border-color: var(--pinkdark);
}

/* .btn-pink.disabled, .btn-pink:disabled {
    color: #fff; background-color: var(--pink);
    border-color: var(--pink);
  } */

.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active, .show>.btn-pink.dropdown-toggle {
  color: #fff;
  background-color: var(--pinkdark);
  border-color: var(--pinkdark);
  
}

/* -----------info---------------------------- */

.table-info, .table-info>th, .table-info>td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover>td, .table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}
.btn-outline-blue {
  color: var(--blue);
  background-color: #fff;
  background-image: none;
  border-color: var(--blue);
}
.btn-outline-blue:hover, .btn-outline-blue.active {
  color: #fff;
  background-color: var(--blue);
  border-color: var(--blue);
}
.btn-outline-blue:active {
  color: #fff;
  background-color: var(--bluedark);
  border-color: var(--bluedark);
}
.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-pink {
  color: var(--pink);
  background-color: #fff;
  background-image: none;
  border-color: var(--pink);
}
.btn-outline-pink:hover, .btn-outline-pink.active {
  color: #fff;
  background-color: var(--pink);
  border-color: var(--pink);
}
.btn-outline-pink:active {
  color: #fff;
  background-color: var(--pinkdark);
  border-color: var(--pinkdark);
}
.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem #e9737333;
}
.alert-pink {
  color: var(--pinkdark);
  background-color: var(--pinklight);
  border-color: #ffe6e6;
}
/* -------------------------- */
accordion .panel-heading {
  padding: 8px;
}
accordion .panel-heading * {
  color: var(--blue);
}
@media screen and (min-width: 430px) {
  accordion .panel{
    width: 1024px;
    margin: auto;
    margin-bottom: 20px;
  }
}
